import { render, staticRenderFns } from "./ResourceCard.vue?vue&type=template&id=771f8f40&scoped=true&"
import script from "./ResourceCard.vue?vue&type=script&lang=js&"
export * from "./ResourceCard.vue?vue&type=script&lang=js&"
import style0 from "./ResourceCard.vue?vue&type=style&index=0&id=771f8f40&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "771f8f40",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/frontend/frontend/src/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('771f8f40')) {
      api.createRecord('771f8f40', component.options)
    } else {
      api.reload('771f8f40', component.options)
    }
    module.hot.accept("./ResourceCard.vue?vue&type=template&id=771f8f40&scoped=true&", function () {
      api.rerender('771f8f40', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/src/shared/molecules/object-visualisations/resource/ResourceCard.vue"
export default component.exports
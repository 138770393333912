export enum SORT_TYPES_ENUM {
  RELEVANCE = 'Relevance',
  MODIFIED_DATE = 'Last Modified',
  CREATION_DATE = 'Last Created'
}

export enum SORT_TYPES_SLIDES_ENUM {
  RELEVANCE = 'Relevance',
  MODIFIED_DATE = 'Last Modified',
  CREATION_DATE = 'Last Created',
  POPULARITY = 'Popularity'
}

export const SORT_TYPE_OPTIONS = {
  SEARCH: [
    SORT_TYPES_ENUM.RELEVANCE,
    SORT_TYPES_ENUM.MODIFIED_DATE,
    SORT_TYPES_ENUM.CREATION_DATE
  ],
  WORKSPACE: [SORT_TYPES_ENUM.MODIFIED_DATE, SORT_TYPES_ENUM.CREATION_DATE]
}

export const SORT_TYPE_DEFAULT = {
  SEARCH: SORT_TYPES_ENUM.RELEVANCE,
  WORKSPACE: SORT_TYPES_ENUM.MODIFIED_DATE,
  VIDEO: SORT_TYPES_ENUM.CREATION_DATE
}

export const ORDERING_MAP = {
  [SORT_TYPES_ENUM.RELEVANCE]: 'score_desc',
  [SORT_TYPES_ENUM.MODIFIED_DATE]: 'modified_date_desc',
  [SORT_TYPES_ENUM.CREATION_DATE]: 'created_date_desc',
  [SORT_TYPES_SLIDES_ENUM.POPULARITY]: 'popularity_desc'
}

export const SORT_TYPE_HEADER = {
  [SORT_TYPES_ENUM.RELEVANCE]: 'Relevance',
  [SORT_TYPES_ENUM.MODIFIED_DATE]: 'Recently modified',
  [SORT_TYPES_ENUM.CREATION_DATE]: 'Recently added',
  [SORT_TYPES_SLIDES_ENUM.POPULARITY]: 'Popularity'
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "resource",
      on: {
        mouseenter: function($event) {
          _vm.hovering = true
        },
        mouseleave: function($event) {
          _vm.hovering = false
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "resource-preview-wrapper",
          class: {
            clickable: _vm.clickable,
            inspectable: !_vm.hasPreviewHover
          },
          on: { click: _vm.onPreviewClick }
        },
        [
          _c("img", {
            staticClass: "resource-preview",
            attrs: { src: _vm.image },
            on: {
              load: function($event) {
                return _vm.$emit("load")
              },
              error: function($event) {
                return _vm.$emit("cantLoad")
              }
            }
          }),
          _vm.hasPreviewHover && _vm.clickable
            ? _c(
                "div",
                { staticClass: "resource-preview-hover" },
                [
                  _vm._t("hover", function() {
                    return [_vm._m(0)]
                  })
                ],
                2
              )
            : _vm._e(),
          _vm.hasOverlay
            ? _c(
                "div",
                { staticClass: "resource-preview-overlay" },
                [_vm._t("overlay", null, { resource: _vm.resource })],
                2
              )
            : _vm.clickable
            ? _c("div", { staticClass: "resource-preview-scrim" })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "resource-preview-tags" },
            [
              _vm.isGenerated
                ? _c("Tag", {
                    attrs: {
                      icon: "ai",
                      text: "Generated",
                      type: "light",
                      size: "xxs",
                      "icon-left": true
                    }
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "resource-preview-tags-slot" },
                [_vm._t("tags", null, { resource: _vm.resource })],
                2
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "resource-info", style: _vm.infoGap },
        [
          _vm._t("subPreview"),
          _c(
            "div",
            { staticClass: "resource-info-row" },
            [
              _c("DocumentTag", {
                staticClass: "resource-info-icon",
                attrs: { mimetype: _vm.resource.integrationfile.mimetype }
              }),
              _c(
                "p",
                {
                  staticClass: "resource-info-title",
                  attrs: { title: _vm.resource.title },
                  on: { click: _vm.onTitleClick }
                },
                [_vm._v(" " + _vm._s(_vm.resource.title) + " ")]
              ),
              _c(
                "div",
                { staticClass: "resource-info-row-btns" },
                [
                  _vm.highlightsEnabled &&
                  (_vm.canWriteLabels || _vm.highlightValue) &&
                  !_vm.hideLabels
                    ? _c(
                        "div",
                        {
                          staticClass: "resource-info-highlight",
                          class: { clickable: _vm.canWriteLabels },
                          on: {
                            mouseenter: _vm.handleHighlightHover,
                            mouseleave: function($event) {
                              _vm.highlightHovered = false
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "resource-info-highlight-icon",
                            attrs: {
                              src: require("@/assets/icons/star" +
                                (_vm.highlightValue ? "-filled" : "") +
                                ".svg"),
                              alt: ""
                            },
                            on: { click: _vm.toggleHighlight }
                          }),
                          _vm.highlightValue
                            ? _c("HighlightHoverInfo", {
                                attrs: {
                                  highlight: _vm.highlightValue,
                                  hovered: _vm.highlightHovered,
                                  "align-left":
                                    _vm.highlightHoverX < _vm.windowWidth / 2
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.hideLabels
                    ? _c("QualityLabels", {
                        staticClass: "resource-info-labels",
                        attrs: {
                          "active-label": _vm.resource.label,
                          "source-id": _vm.resource.uuid,
                          "bordered-icons": false,
                          "hide-button-text": true,
                          "small-labels": ""
                        }
                      })
                    : _vm._e(),
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        label: _vm.isBookmarked
                          ? "Remove bookmark"
                          : "Add bookmark",
                        position: "is-top",
                        type: "is-dark"
                      }
                    },
                    [
                      _c("div", { staticClass: "resource-info-bookmark" }, [
                        _c("img", {
                          staticClass: "resource-info-bookmark-icon",
                          attrs: {
                            src: require("@/assets/icons/bookmark" +
                              (_vm.isBookmarked ? "-filled" : "") +
                              ".svg"),
                            alt: ""
                          },
                          on: { click: _vm.toggleBookmark }
                        })
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("ResourceInfo", {
            staticClass: "resource-info-integration",
            attrs: {
              "resource-integration": _vm.resource.integrationfile,
              hover: _vm.hovering,
              "hide-integration-path": true
            },
            on: { "on-path-click": _vm.onPathClick }
          }),
          _vm._t("subTitle")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "resource-preview-hover-icon-wrapper" }, [
      _c("img", {
        staticClass: "resource-preview-hover-icon",
        attrs: { src: require("@/assets/icons/external-bold.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div
    class="resource"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <div
      class="resource-preview-wrapper"
      :class="{ clickable, inspectable: !hasPreviewHover }"
      @click="onPreviewClick"
    >
      <img
        :src="image"
        class="resource-preview"
        @load="$emit('load')"
        @error="$emit('cantLoad')"
      />
      <div v-if="hasPreviewHover && clickable" class="resource-preview-hover">
        <slot name="hover">
          <div class="resource-preview-hover-icon-wrapper">
            <img
              src="@/assets/icons/external-bold.svg"
              alt=""
              class="resource-preview-hover-icon"
            />
          </div>
        </slot>
      </div>
      <div v-if="hasOverlay" class="resource-preview-overlay">
        <slot name="overlay" :resource="resource"></slot>
      </div>
      <div v-else-if="clickable" class="resource-preview-scrim"></div>
      <div class="resource-preview-tags">
        <Tag
          v-if="isGenerated"
          icon="ai"
          text="Generated"
          type="light"
          size="xxs"
          :icon-left="true"
        />
        <div class="resource-preview-tags-slot">
          <slot name="tags" :resource="resource"></slot>
        </div>
      </div>
    </div>
    <div class="resource-info" :style="infoGap">
      <slot name="subPreview"></slot>
      <div class="resource-info-row">
        <DocumentTag
          :mimetype="resource.integrationfile.mimetype"
          class="resource-info-icon"
        />
        <p
          class="resource-info-title"
          :title="resource.title"
          @click="onTitleClick"
        >
          {{ resource.title }}
        </p>
        <div class="resource-info-row-btns">
          <div
            v-if="
              highlightsEnabled &&
                (canWriteLabels || highlightValue) &&
                !hideLabels
            "
            class="resource-info-highlight"
            :class="{ clickable: canWriteLabels }"
            @mouseenter="handleHighlightHover"
            @mouseleave="highlightHovered = false"
          >
            <img
              :src="
                require(`@/assets/icons/star${
                  highlightValue ? '-filled' : ''
                }.svg`)
              "
              class="resource-info-highlight-icon"
              alt=""
              @click="toggleHighlight"
            />
            <HighlightHoverInfo
              v-if="highlightValue"
              :highlight="highlightValue"
              :hovered="highlightHovered"
              :align-left="highlightHoverX < windowWidth / 2"
            />
          </div>
          <QualityLabels
            v-if="!hideLabels"
            class="resource-info-labels"
            :active-label="resource.label"
            :source-id="resource.uuid"
            :bordered-icons="false"
            :hide-button-text="true"
            small-labels
          />
          <b-tooltip
            :label="isBookmarked ? 'Remove bookmark' : 'Add bookmark'"
            position="is-top"
            type="is-dark"
          >
            <div class="resource-info-bookmark">
              <img
                :src="
                  require(`@/assets/icons/bookmark${
                    isBookmarked ? '-filled' : ''
                  }.svg`)
                "
                class="resource-info-bookmark-icon"
                alt=""
                @click="toggleBookmark"
              />
            </div>
          </b-tooltip>
        </div>
      </div>
      <ResourceInfo
        class="resource-info-integration"
        :resource-integration="resource.integrationfile"
        :hover="hovering"
        :hide-integration-path="true"
        @on-path-click="onPathClick"
      />
      <slot name="subTitle"></slot>
    </div>
  </div>
</template>

<script>
import {
  addBookmarkToResource,
  deleteBookmarkFromResource
} from '@/services/bookmarkService'
import {
  eventContext,
  sendFileClickThroughEvent,
  sendFolderClickThroughEvent,
  sendInspectEvent
} from '@/services/feedbackService'
import {
  highlightResource,
  removeHighlightFromResource
} from '@/services/highlightService'
import HighlightHoverInfo from '@/views-v2/home/highlight/HighlightHoverInfo.vue'
import Tag from '@c/library/Tag.vue'
import { Mimetypes, mimetypeToType } from '@c/mimetypes'
import { isEmpty } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import DocumentTag from '../../tags/DocumentTag.vue'
import QualityLabels from './subcomponents/QualityLabels.vue'
import ResourceInfo from './subcomponents/ResourceInfo.vue'

export default {
  name: 'ResourceCard',
  components: {
    QualityLabels,
    ResourceInfo,
    HighlightHoverInfo,
    DocumentTag,
    Tag
  },
  props: {
    resource: {
      type: Object,
      required: true
    },
    rank: {
      type: Number,
      required: true
    },
    location: {
      type: String,
      default: 'search results'
    },
    highlight: {
      type: Object,
      default: undefined
    },
    hideLabels: {
      type: Boolean,
      default: false
    },
    searchId: {
      type: String,
      default: ''
    },
    clickable: {
      type: Boolean,
      default: true
    },
    hasOverlay: {
      type: Boolean,
      default: false
    },
    modalRootProps: {
      type: Object,
      default: () => ({})
    },
    modalCallback: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    hovering: false,
    highlightHovered: false,
    highlightHoverX: -1,
    highlightValue: undefined,
    isBookmarked: false
  }),
  computed: {
    ...mapGetters(['canWriteLabels', 'highlightsEnabled']),
    image() {
      return this.resource.subresources[0]?.preview
    },
    hasPreviewHover() {
      return this.resource?.type === 'video' || false
    },
    windowWidth() {
      return window.innerWidth
    },
    infoGap() {
      return !isEmpty(this.resource.label) ||
        !isEmpty(this.highlightValue) ||
        this.canWriteLabels
        ? ''
        : `gap: 0.5rem;`
    },
    selectableMimeType() {
      return [Mimetypes.PPTX, Mimetypes.PPT, Mimetypes.GOOGLE_SLIDES].includes(
        mimetypeToType[this.resource.integrationfile.mimetype]
      )
    },
    isGenerated() {
      return (
        this.resource?.integrationfile?.integration === 'google_cloud_storage'
      )
    }
  },
  mounted() {
    this.highlightValue = this.highlight
    this.isBookmarked = this.resource.bookmark !== ''
    if (!this.image) this.$emit('cantLoad')
  },
  methods: {
    ...mapActions(['setResourceModal']),
    onPreviewClick() {
      if (this.hasPreviewHover) {
        this.gotoResource()
        return
      }
      sendInspectEvent(this.$route.params.workspace_id, {
        resource_trace_id: this.resource.trace_id,
        subresource_trace_id: this.resource.subresources[0].trace_id,
        tab: this.$route.params.tab || 'all',
        context: eventContext.resource,
        rank: this.rank,
        comment: `opening resource modal with card click from ${this.location}`
      })
      this.inspectResource()
    },
    onTitleClick() {
      this.gotoResource()
    },
    async gotoResource() {
      sendFileClickThroughEvent(this.$route.params.workspace_id, {
        resource_trace_id: this.resource.trace_id,
        tab: this.$route.params.tab || 'all',
        context: eventContext.resource,
        comment: `from resource in ${this.location}`,
        rank: this.rank,
        ...(this.searchId ? { search_id: this.searchId } : {})
      })
      const win = window.open(this.resource.url, '_blank')
      win?.focus()
    },
    async inspectResource() {
      this.setResourceModal({
        resource_id: this.resource.uuid,
        resource_level: 'resource',
        resource_modal_props: {
          workspaceId: this.$route.params.workspace_id,
          initialPage: 1,
          selectable: this.selectableMimeType,
          scrollTo: '',
          similarPages: [],
          rank: this.rank,
          resourceType: this.resource.type,
          resourceTraceId: this.resource.trace_id
        },
        buefy_modal_props: {},
        root_props: {
          ...this.modalRootProps
        }
      })
      this.modalCallback()
    },
    async onPathClick() {
      sendFolderClickThroughEvent(this.$route.params.workspace_id, {
        resource_trace_id: this.resource.trace_id,
        tab: this.$route.params.tab || 'all',
        context: eventContext.resource,
        comment: `from resource in ${this.location}`,
        rank: this.rank,
        ...(this.searchId ? { search_id: this.searchId } : {})
      })
    },
    async toggleHighlight() {
      if (!this.canWriteLabels) return
      try {
        if (!isEmpty(this.highlightValue)) {
          await removeHighlightFromResource(
            this.$route.params.workspace_id,
            this.resource.uuid,
            this.$route.query.act_as
          )
          this.highlightValue = undefined
        } else {
          this.highlightValue = await highlightResource(
            this.$route.params.workspace_id,
            this.resource.uuid,
            this.$route.query.act_as
          )
        }
      } catch (e) {
        this.$console.debug(
          'Something went wrong when when changing highlight',
          e
        )
        this.$toast.error(e, 'changing the highlight')
      }
    },
    handleHighlightHover(e) {
      this.highlightHovered = true
      this.highlightHoverX = e.clientX
    },
    async toggleBookmark() {
      try {
        if (this.isBookmarked) {
          await deleteBookmarkFromResource(
            this.$route.params.workspace_id,
            this.resource.uuid,
            this.$route.query.act_as
          )
          this.isBookmarked = false
        } else {
          await addBookmarkToResource(
            this.$route.params.workspace_id,
            this.resource.uuid,
            this.$route.query.act_as
          )
          this.isBookmarked = true
        }
      } catch (error) {
        this.$toast.error(
          error,
          `${this.isBookmarked ? 'removing' : 'adding'} bookmark`
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.resource {
  &-preview {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &-wrapper {
      width: 100%;
      aspect-ratio: 16 / 9;
      border-radius: 4px;
      border: 1px solid rgba(#000, 8%);
      background: #f5f5f5;
      overflow: hidden;
      position: relative;

      &.clickable {
        cursor: pointer;

        &:hover > .resource-preview-hover {
          opacity: 1;
        }

        &.inspectable {
          cursor: zoom-in;

          &:hover > .resource-preview-scrim {
            opacity: 0.16;
          }
        }
      }
    }

    &-hover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(#000, 32%);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 400ms ease;

      &-icon {
        height: 1.5rem;
        filter: brightness(0) invert(1);
        &-wrapper {
          height: 3rem;
          width: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 999rem;
          background: rgba(#000, 16%);
        }
      }
    }

    &-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(#000, 32%);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-scrim {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.0127407) 6.47%,
        rgba(0, 0, 0, 0.0485926) 12.46%,
        rgba(0, 0, 0, 0.104) 18.04%,
        rgba(0, 0, 0, 0.175407) 23.29%,
        rgba(0, 0, 0, 0.259259) 28.27%,
        rgba(0, 0, 0, 0.352) 33.08%,
        rgba(0, 0, 0, 0.450074) 37.77%,
        rgba(0, 0, 0, 0.549926) 42.42%,
        rgba(0, 0, 0, 0.648) 47.11%,
        rgba(0, 0, 0, 0.740741) 51.91%,
        rgba(0, 0, 0, 0.824593) 56.9%,
        rgba(0, 0, 0, 0.896) 62.15%,
        rgba(0, 0, 0, 0.951407) 67.73%,
        rgba(0, 0, 0, 0.987259) 73.72%,
        #000000 80.19%
      );
      transform: rotate(180deg);
      opacity: 0;
      transition: opacity 0.4s ease;
    }

    &-tags {
      position: absolute !important;
      bottom: 0.5rem;
      right: 0.5rem;
      left: 0.5rem;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      gap: 0.5rem;

      &-slot {
        display: contents;
      }
    }
  }

  &-info {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 0.5rem;

    &-row {
      display: grid;
      max-width: 100%;
      grid-template-columns: 1.5rem 1fr auto;
      align-items: center;
    }

    &-icon {
      width: 1.2rem;
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
    }

    &-title {
      display: inline-block;
      vertical-align: middle;
      color: #115efb;
      font-weight: 600;
      cursor: pointer;
      text-align: justify;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        text-decoration: underline;
      }
    }

    &-highlight {
      position: relative;
      padding: 0.5rem;
      box-sizing: initial;
      height: 1.2rem;
      width: 1.2rem;
      border-radius: 999rem;
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
      justify-content: center;

      &.clickable {
        cursor: pointer;

        &:hover {
          background: rgba(#000, 6%);
        }
      }

      &-icon {
        height: 1.2rem;
      }
    }

    &-bookmark {
      position: relative;
      padding: 0.5rem;
      box-sizing: initial;
      height: 1.2rem;
      width: 1.2rem;
      border-radius: 999rem;
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
      justify-content: center;

      &:hover {
        background: rgba(#000, 6%);
      }

      &-icon {
        height: 1rem;
      }
    }

    &-labels {
      display: inline-flex;
      vertical-align: middle;
    }

    &-integration {
    }
  }
}
</style>
